/* eslint no-console: ["error", { allow: ["error"] }] */
/**
 * Global error handler
 * @param  {string} message    Error message
 * @param  {string} scriptUrl  URL on which the error occured
 * @param  {int} lineNumber    Line number
 * @param  {int} column        Column number (not always supplied)
 * @param  {Error} error       Error object (not always supplied)
 * @return {Boolean}           Boolean indiciating default browser error dialog should be shown
 */
window.onerror = function (message, scriptUrl, lineNumber, columnNumber, error) {
  window?.newrelic?.noticeError(error);
  console?.error(error);

  // Surpress error dialog
  return true;
};
